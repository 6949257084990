/* Google Font CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaisei Tokumin:wght@200;300;400;500;600;700&display=swap');

:root{
    --black-color: #0B0243;
    --white-color: #fff;
    --light-gray: #D1D5DB;
    --gray: #9CA3AF;
    --light-black: #374151;
    --dark-blue: #0B0243;
    --dark-blue-hover: #3B82F6;
    --link-color: #3B82F6;
    --link-color-hover: #0B0243;
    --text-color: #6E7583;
    --heading-color:#111827;

    /* Alerts */
    --primary: #3B82F6;
    --background: #0B0243;
    --warning: #f2a600;
    --success: #12c99b;
    --error: #e41749;
    --dark: #0B0243;
}
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a{
    text-decoration: none;
    transition: .2s;
    color: var(--link-color);
    font-size: 14px;
}
a:hover{
    color: var(--link-color-hover);
}
ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}
button{
    transition: .2s;
    border: 1px solid #eee;
    cursor: pointer;
}
.text-right{
    text-align: right !important;
}