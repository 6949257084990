@media only screen and (max-width: 1440px) {
     /* Main Section  */

     .calendar-wrapper {
        width: 600px;
    }

} /* Close 1440px */

@media only screen and (max-width: 1024px) {
    /* Sidebar  */
    .sidebar ul li a {
        padding: 20px 20px;
    }
   
    /* Main Section  */
    .home_content {
        padding: 40px 20px;
        width: calc(100% - 266px);
        left: 266px;
    }
    .sidebar ul li.comp-logo-sidebar a img {
        width: 220px;
    }
    .custom-modal-container {
        width: 90%;
    }
    .date-fields {
        flex-direction: column;
    }
    .date-fields .date-input {
        padding: 5px;
    }
    .date-fields span {
        padding: 5px !important;
    }
    td.tbl-form-field span {
        padding: 10px 0px !important;
    }
    td.tbl-buttons-field {
        display: flex;
        flex-direction: column;
    }
    .form-table .action-btns {
        padding: 0px 5px;
    }

} /* Close 1024px */

@media only screen and (max-width: 991px) {

    /* Sidebar  */
    li.comp-logo-sidebar.mobile {
        display: block !important;
        width: 100px;
        margin: auto;
        padding-top: 40px;
    }
    li.comp-logo-sidebar.mobile .comp-logo-link {
        width: 100%;
        padding: 0 !important;
    }
    li.comp-logo-sidebar {
        display: none;
    }
    .comp-logo-sidebar.mobile .comp-logo-link img {
        width: 100%;
    }
    button.backmenu-icon.close {
        right: 20px;
    }
    .sidebar {
        transition: all 1s ease !important;
    }
    button.backmenu-icon {
        display: block;
        visibility: visible;
        transition-duration: 0s;
    }
    button.backmenu-icon.close.hide {
        visibility: hidden;
        transition-duration: 0s;
    }
    aside.sidebar.close-menu {
        left: -270px !important;
    }
    aside.sidebar.open-menu {
        left: 0px !important;
    }
    .backmenu-icon.hide {
        visibility: hidden ;
    }

     /* Main Section  */
     section.home_content {
        width: 100%;
        left: 0;
        padding: 40px;
    }
    .date-fields {
        flex-direction: row;
    }
    .date-fields .date-input {
        padding: 10px;
    }
    .date-fields span {
        padding: 10px !important;
    }
    td.tbl-form-field span {
        padding: 10px 10px !important;
    }
    .search-estimates-wrapper {
        justify-content: space-around;
        margin-top: 30px;
    }
    section.main-form-section.thank-you {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        margin-top: 50px;
    }
    .calendar-wrapper {
        width: auto;
    }
    section.main-form-section.thank-you .form-section-wrapper {
        margin: 0;
        width: 66%;
    }
} /* Close 991px */

@media only screen and (max-width: 768px) {
    .form-section-wrapper,
    .form-section-wrapper form {
        width: 100%;
    }
    .dashboard-header-section h4 {
        text-align: center;
    }
    /* Main Section  */
    .form-table thead {
        display: none !important;
    }
    label.field-label {
        display: block;
    }
    .form-table tbody tr {
        display: flex;
        flex-direction: column;
        margin: 20px auto;
        box-shadow: 0px 5px 10px #00000014;
        padding: 15px;
        background-color: #F9F9F9 !important;
    }
    .form-table, .form-group-wrapper {
        width: 70% !important;
        margin: auto;
        box-shadow: none;
    }
    h4.page-title {
        width: 70% !important;
        margin: auto;
    }
    .add-new-row {
        right: 10px;
        top: auto;
        bottom: 60px;
    }
    .form-table .action-btns {
        position: absolute;
        right: -25px;
        bottom: -25px;
    }
    .form-table .tbl-type-field {
        width: 100%;
    }
    .form-table .tbl-price-field {
        width: 100%;
    }
    .form-table .form-input-with-close .table-input {
        width: 100%;
    }
    table.form-table.full-table, table.form-table.total-calculation-table, table.form-table.final-calculation-table {
        width: 70% !important;
        margin: auto !important;
    }
    table.form-table tbody tr td.tbl-form-field, table#estimates-table tbody tr td.tbl-long-field {
        display: flex;
        align-items: center;
        padding: 5px 10px;
    }
    table.form-table.full-table tbody tr td span {
        padding: 5px 10px !important;
    }
    table.form-table.full-table .action-btns {
        position: static;
    }
    .section-table {
        flex-direction: column;
        justify-content: center;
    }
    td.tbl-buttons-field {
        flex-direction: row;
    }
    .form-table .action-btns {
        padding: 6px 5px;
    }
    table.simple-table tr td:first-child {
        padding: 5px 20px 5px 0px;
        width: 40% !important;
    }
    table.simple-table tr td.tbl-long-field {
        width: 60% !important;
    }
    td.tbl-form-field label.field-label, table#estimates-table tbody tr td.tbl-long-field .field-label, #estimates-table tbody tr td label.field-label {
        padding-bottom: 0px;
    }
    table.form-table.total-calculation-table thead, table.form-table.final-calculation-table thead {
        display: flex !important;
        width: 100%;
    }
    table.form-table.total-calculation-table thead tr, table.form-table.final-calculation-table thead tr {
        width: 100%;
        display: flex;
        place-content: space-between;
        background: #0b0243;
        border-radius: 5px 5px 0 0;
        overflow: hidden;
    }
    table.form-table.total-calculation-table tbody tr, table.form-table.final-calculation-table tbody tr {
        background-color: #fff !important;
        box-shadow: none !important;
        margin: 0 !important;
        flex-direction: row;
        place-content: space-between;
    }
    table.form-table.total-calculation-table, table.form-table.final-calculation-table {
        box-shadow: 0px 5px 10px #00000014 !important;
    }
    table.form-table.total-calculation-table tbody tr:last-child, table.form-table.final-calculation-table tbody tr:last-child{
        place-content:space-around !important;
    }
    .back-page-container {
        margin-top: 20px;
    }
    .simple-table {
        width: 80% !important;
        box-shadow: 0px 5px 10px #00000014 !important;
        padding: 15px 15px;
        margin: auto;
    }
    .form-table tbody tr td {
        width: 100% !important;
    }
    .form-table .tbl-buttons-field {
        place-content: start !important;
    }
    .search-est-items.w-100 {
        width: 70% !important;
        margin: auto;
    }
    .form-group-wrapper{
        margin-top: 30px !important;
    }
    #estimates-table tbody tr td.tbl-qty-field,  #estimates-table tbody tr td.tbl-price-field.text-center {
        display: flex;
        align-items: center;
    }
    #estimates-table tbody tr td .form-input-with-close {
        padding-left: 10px;
    }
    #estimates-table tbody tr td input.table-input  {
        margin-left: 10px;
    }
    section.main-form-section.thank-you .form-section-wrapper {
        width: 86%;
    }
    .message-form-left,
    .calendar-content {
        width: 100%;
    }
    .message-form{
        flex-direction: column;
        height: 100%;
    }
    .message-form-left {
        margin-bottom: 50px;
        margin-top: -115px;
    }
    .calendar-content {
        height: 60rem;
    }
    .card-box.x3-box,
    .promocodes-widget {
        width: 100%;
    }
    .widget-wrapper {
        flex-direction: column;
    }
    .help-img {
        padding: 10px;
    }
    .help-img img {
        width: 220px;
    }
    .instructions {
        padding: 30px;
    }
    .partial-computation-table {
        display: none;
    }
} /* Close 768px */

@media only screen and (max-width: 767px) {
    /* Main Section  */
    section.home_content {
        padding: 20px;
    }
    .form-table, .simple-table, .form-group-wrapper {
        width: 90% !important;
        margin: auto;
        box-shadow: none;
    }
    h4.page-title {
    width: 90% !important;
     margin: auto;
    }
    table.form-table.full-table, table.form-table.total-calculation-table, table.form-table.final-calculation-table {
        width: 90% !important;
        margin: auto !important;
    }
    .search-est-items.w-100 {
        width: 90% !important;
        margin: auto;
    }
    .search-estimates-wrapper {
        display: flex;
        flex-direction: column;
    }
    .search-est-items.sei-with-icons {
        margin-top: 20px;
    }
    .simple-table {
        width: 90% !important;
    }
    .dataTables_length{
        font-size: 0;
    }
    .datatable-wrapper .form-table {
        margin: 0 5px;
    }
    .dataTables_info {
        width: 100%;
        text-align: center;
    }
    .dataTables_paginate {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
    
} /* Close 767px */

@media only screen and (max-width: 480px) {
    /* Main Section  */
    .home_content {
        position: relative !important;
    }
    section.home_content {
        padding: 20px 10px;
    }
    .section-table {
        align-items: center;
    }
    .dashboard-header-section h4 {
        font-size: 30px;
    }
    .add-new-row {
        bottom: -20px;
    }
    table.simple-table tbody tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    table.simple-table tr td:first-child span {
        color: #9CA3AF;
        font-size: 13px;
        padding: 0 !important;
    }
    table.simple-table tr td.tbl-long-field, table.simple-table tr td:first-child {
        width: 100% !important;
    }
    button.backmenu-icon {
        right: -40px;
    }
    .form-grouped.from-group-checkbox {
        align-items: start;
    }
    .form-grouped.from-group-checkbox input.table-input {
        margin-top: 8px;
    }
    .form-group-wrapper .row-container:first-child {
        flex-direction: column;
        align-items: start;
    }
    .form-group-wrapper .row-container:first-child .col-6 {
        width: 100%;
    }
    .expires-notice {
        flex-wrap: wrap;
    }
    /* Sidebar  */
    button.backmenu-icon .w-6 {
        width: 25px !important;
    }
    section.main-form-section.thank-you .form-section-wrapper {
        width: 90%;
    }
    section.main-form-section.thank-you .form-section-wrapper {
        width: 96%;
    }
    .message-form {
        flex-direction: column;
        height: 100%;
        padding: 40px 0px;
    }
    .widget-bg-color-icon .bg-icon {
        height: 50px;
        width: 50px;
        font-size: 26px;
    }
    .card-box p.text-muted {
        font-size: 14px;
    }
    .dashboard-header-section {
        margin-top: 20px;
    }
    .dashboard-header-section .page-title-sm {
        font-weight: 600;
    }
    .custom-modal-inner {
        flex-direction: column;
    }
    .help-img img {
        width: 150px;
    }
    .custom-modal-inner h2 {
        text-align: center;
    }
    
} /* Close 480px */


@media only screen and (max-width: 425px) {
    .form-section-wrapper form {
        padding: 40px 20px;
    }
    .form-header {
        height: 75px;
        width: 100%;
    }
    .main-form-section {
        background-position: top;
        background-size: contain;
    }
    .submit-button {
        width: 100%;
    }
    /* Main Menu  */
    section.home_content {
        padding: 20px 5px;
    }
    .submit-button {
        width: 80% !important;
    }
    table.form-table.total-calculation-table .tbl-long-field .submit-button, table.form-table.final-calculation-table {
        width: 100% !important;
    }
    button.submit-button.w-100 {
        width: 100% !important;
    }
    .form-table tbody tr td span {
        padding: 0;
    }
    .form-table tbody tr {
        padding: 5px 10px;
    }
    .nestable-item-name span.drag-icon {
        height: 45px;
    }
} /* Close 425px */

@media only screen and (max-width: 375px) {

} /* Close 375px */