*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter" , sans-serif;
}
a, p, div, button{
    font-size: 14px;
}
ol, ul {
    margin-top: 0;
    margin-bottom: 10px;
}
h1, h2, h3, h4, h5, h6{
    color: var(--heading-color);
    font-family: "Kaisei Tokumin";
}
.mt-0 {
    margin-top: 0px !important;
}
.mt-20{
    margin-top: 20px !important;
}
.mt-50{
    margin-top: 50px !important;
}
.spinner {
    animation: spin infinite 2s linear;
}
.w-100{
    width: 100% !important;
}
.text-center{
    text-align: center !important;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.main-form-section{
    background: url(../img/main-background.png);
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-section-wrapper {
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 75px 20px;
}
.form-header {
    background: url(../img/company-logo.png);
    height: 108px;
    width: 450px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 30px;
}
.form-header h1 {
    text-align: center;
    text-transform: uppercase;
    margin: 0;
}
.form-section-wrapper form {
    width: 500px;
    padding: 0;
    background: #fff;
}
.form-group {
    display: flex;
    position: relative;
}
.form-input {
    padding: 0 20px;
    height: 48px;
    width: 100%;
    background: transparent;
    font-size: 14px;
    transition: .2s;
    outline: 0;
    border: 1px solid var(--light-gray);
    border-radius: 5px;
}
.form-wrapper {
    margin: 15px 0;
}
.form-wrapper label {
    font-size: 14px;
    color: var(--light-black);
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
}
.sign-in-text ul li {
    font-size: 12px;
}
.sign-in-text ul li a {
    font-size: 14px;
    font-weight: 400;
}
.sign-in-text {
    padding: 20px 0;
    padding-top: 0;
}
.s-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 13px;
}
.submit-button {
    border: 0;
    background: var(--dark-blue);
    padding: 14px 20px;
    display: block;
    width: 140px;
    color: var(--white-color);
    border-radius: 5px;
    font-size: 14px;
    color: #fff !important;
}
.submit-button:hover {
    background: var(--dark-blue-hover);
}
.submit-button.white-border-btn {
    background: #fff;
    color: #0B0243 !important;
    border: 1px solid #0B0243;
}
.submit-button.white-border-btn:hover {
    color: var(--dark-blue-hover) !important;
    border: 1px solid var(--dark-blue-hover);
}
.s-btn-group .or-text {
    font-size: 14px;
    display: block;
    padding: 15px;
}
.google-button {
    padding: 15px 20px;
    border-radius: 10px;
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #298eff;
    color: var(--white-color);
}
.google-button:hover {
    background: #145daf;
}
.google-button svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}
.form-group i.show-icon {
    position: absolute;
    right: 10px;
    top: 16px;
    font-size: 20px;
    color: #b2b2b2;
    cursor: pointer;
}
.spinner-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background: #eee;
}
.remember-me {
    display: flex;
    align-items: center;
    justify-content: center;
}
.remember-me label {
    margin-left: 14px;
    color: var(--gray);
    font-size: 14px;
    font-weight: 300;
}
/* Alerts */
.alert {
    width: 100%;
    border-radius: 10px;
    padding: 16px 22px 17px 20px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: space-between;
}
.alert-warning {
    background: var(--warning);
}
.alert-success {
    background: var(--success);
}
.alert-primary {
    background: var(--primary);
}
.alert-dark {
    background: var(--dark);
}
.alert-error {
    background: var(--error);
}
.alert .icon__wrapper {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.253);
    display: flex;
    align-items: center;
    justify-content: center;
}
.alert .icon__wrapper span {
    font-size: 20px;
    color: var(--white-color);
    line-height: 1;
}
.alert p {
    color: var(--white-color);
    font-family: Verdana;
    margin-left: 10px;
}
.alert p a,
.alert p a:visited,
.alert p a:active {
    color: var(--white-color);
    text-decoration: underline;
}
.alert .open {
    margin-left: auto;
    margin-right: 5px;
}
.alert .close, .alert .open {
    color: var(--white-color);
    transition: transform 0.5s;
    font-size: 16px;
    cursor: pointer;
    line-height: 1;
}
.alert .close:hover, .alert .open:hover {
    transform: scale(1.3);
}
.alert .alert-content {
    display: flex;
    align-items: center;
}
/* Dashboard */
::selection{
    color:var(--white-color);
    background: var(--black-color);
} 
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--white-color);
    padding: 0;
    z-index: 99;
    transition: all 0.5s ease;
    box-shadow: 3px 0px 10px #00000014;
}
.sidebar.active{
    width:240px;
}
.sidebar .logo_content .logo{
    color:var(--white-color);
    display: flex;
    height:50px;
    width:100%;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition:all 0.5s ease;
}
.sidebar.active .logo_content .logo{
    opacity:1;
    pointer-events: none;
}
.logo_content .logo .logo_name{
    font-size: 20px;
    font-weight: 400;
}
.sidebar #btn{
    position:absolute;
    color:var(--white-color);
    top:6px;
    left:50%;
    font-size: 22px;
    height:50px;
    width:50px;
    text-align: center;
    line-height: 50px;
    transform: translateX(-50%);
    cursor: pointer;
}
.sidebar.active #btn{
    left:90%;
}
.sidebar ul{
    margin-top:20px;
}
.sidebar ul li {
    position: relative;
    width: 100%;
    list-style: none;
    line-height: 1;
}
.sidebar ul li.comp-logo-sidebar a img {
    width: 272px;
}
.sidebar ul li.logout-nav {
    position: fixed !important;
    bottom: 0;
    width: 18.4% !important;
}
.sidebar ul li .tooltip{
    position: absolute;
    left: 139px;
    top: 22px;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    height: 35px;
    width: 150px;
    background: var(--dark-blue-hover);
    line-height: 35px;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    transition: 0s;
    opacity: 0;
    pointer-events: none;
    font-size: 14px;
    display: block;
    color: var(--white-color);
}
.sidebar ul li .tooltip::before{
    content:'';
    width:0;
    height:0;
    border-top:10px solid transparent;
    border-bottom:10px solid transparent;
    border-right:10px solid var(--dark-blue-hover);
    position:absolute;
    left:-8px;
    top:7px;
}
.sidebar.active ul li .tooltip{
    display:none;
}
.sidebar ul li:hover .tooltip{
    transition:all 0.5s ease;
    opacity:1;
    top:50%;
}
.sidebar ul li input{
    position:absolute;
    height:100%;
    width:100%;
    left:0;
    top:0;
    border-radius: 12px;
    outline:none;
    border:none;
    background: #1d1b31;
    padding-left:50px;
    font-size: 18px;
    color:var(--white-color);
}
.sidebar ul li .bx-search{
    position:relative;
    z-index: 99;
    color:var(--white-color);
    font-size: 22px;
    transition:all 0.5s ease;
}
.sidebar ul li .bx-search:hover{
    background:var(--white-color);
    color:#1d1b31;
}
.sidebar ul li a {
    color: var(--text-color);
    display: flex;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    transition: all 0.4s ease;
    padding: 20px 40px;
}
.sidebar ul li a:hover:not(.comp-logo-link),
.sidebar ul li a.active {
    color: var(--white-color);
    background: var(--link-color-hover);
}
.sidebar ul li i {
    font-size: 20px;
    font-weight: 400;
    border-radius: 12px;
    text-align: center;
    margin-right: 20px;
}
.sidebar .links_name{
    font-size: 15px;
    font-weight: 400;
    opacity:0;
    pointer-events: none;
    transition:all 0.3s ease;
}
.sidebar.active .links_name{
    transition:0s;
    opacity:1;
    pointer-events: auto;
}
.sidebar .content{
    position:absolute;
    color:var(--white-color);
    bottom:0;
    left:0;
    width:100%;
}
.sidebar .content .user{
    position:relative;
    padding:10px 6px;
    height:60px;
    background: none;
    transition:all 0.4s ease;
}
.sidebar.active .content .user{
    background:var(--light-black);
}
.content .user .user_details{
    display:flex;
    align-items:center;
    opacity:0;
    pointer-events: none;
    white-space: nowrap;
    transition:all 0.4s ease;
}
.sidebar.active .user .user_details{
    opacity:1;
    pointer-events: auto;
}
.content .user .user_details img{
    height:45px;
    width:45px;
    object-fit: cover;
    border-radius: 12px;
}
.name_job{
    margin-left:10px;
}
.user_details .name{
    font-size: 15px;
    font-weight: 400;
}
.user_details .job{
    font-size: 12px;
    color: var(--white-color);
}
#log_out{
    position:absolute;
    bottom:5px;
    left:50%;
    transform:translateX(-50%);
    min-width: 50px;
    line-height: 50px;
    font-size: 20px;
    border-radius: 12px;
    text-align: center;
    transition:all 0.4s ease;
}
.sidebar.active .user #log_out{
    left:80%;
    background:none;
}
.home_content {
    position: absolute;
    height: 100%;
    width: calc(100% - 353px);
    left: 353px;
    background: var(--white-color);
    padding: 40px;
    transition: all 0.5s ease;
    overflow: auto;
}
.main-content-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 40px 20px;
    position: relative;
}
.sidebar.active ~ .home_content{
    z-index:100;
    width:calc(100% - 240px);
    left:240px;
}
.page-title {
    font-size: 36px;
    font-weight: 600;
    margin: 30px 0;
    color: var(--heading-color);
}
.breadcrumb {
    padding: 8px 15px;
    list-style: none;
    border-radius: 4px;
    background-color: transparent;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-left: 0px;
}
.breadcrumb>li {
    display: inline-block;
}
.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0";
}
.breadcrumb>.active {
    color: #777;
}
.breadcrumb>li a {
    color: #0676ed;
}
.breadcrumb>li a:hover{
    color: var(--dark-blue-hover);
}
.card-box {
    padding: 20px;
    border: 1px solid rgba(54, 64, 74, 0.05);
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    margin-bottom: 20px;
    background-color: #ffffff;
}
.header-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 8px;
    color: #505458;
}
.sign-in-text ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
}

/* Email Checker */
.email-checker-container,
.row-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.row-half {
    width: 50%;
    padding: 15px 30px 30px 30px;
}
.row-half:last-child{
    border-left: 1px solid #eee;
}
.card-box-container {
    background: white;
    box-shadow: 0 0 0 1px rgba(50, 50, 95, 0.02), 0 6px 8px -2px rgba(50, 50, 95, 0.08), 0 3px 4px -1px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    padding: 0;
    position: relative;
    width: 100%;
    transition: 0.3s background, 0.3s opacity;
}
.card-title {
    font-size: 18px;
    color: #505458;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #eee;
}
.card-title i {
    line-height: 1;
    margin-right: 5px;
    font-size: 22px;
}
.placeholder {
    color: rgba(120, 110, 175, 0.5);
    font-family: "Roboto Mono", Consolas, "Bitstream Vera Sans Mono", "Andale Mono", Monaco, "DejaVu Sans Mono", "Lucida Console";
    line-height: 1.6em;
    padding: 20px;
    pointer-events: none;
    position: absolute;
}

/* Custom Tables */
.form-table {
    width: 97%;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #00000014;
    border-radius: 5px;
    border-spacing: 0;
    border-collapse: collapse;
}
.form-table > tbody > tr:nth-of-type(odd){
    background-color: #F9F9F9 !important;
}
.form-table thead th {
    background: var(--black-color);
    border: 0;
    font-size: 14px;
    color: var(--white-color);
    padding: 14px 20px;
    text-align: left;
    font-weight: 400;
}
.form-table thead th:last-child{
    border-top-right-radius: 5px;
}
.form-table thead th:first-child{
    border-top-left-radius: 5px;
}
.form-table tbody tr td{
    padding: 7px 10px;
    color: var(--gray);
}
.form-table tbody tr td span{
    color: var(--dark-blue);
    padding: 10px 10px;
    display: block;
}
.form-table .table-input {
    border: 1px solid var(--light-gray);
    border-radius: 5px;
    outline: 0;
    font-size: 14px;
    padding: 10px;
    display: block;
    width: 100%;
}
.form-table .form-input-with-price, 
.form-table .form-input-with-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.form-table .form-input-with-price .table-input {
    text-align: right;
    padding-left: 25px;
}
.form-table .form-input-with-price .sign {
    position: absolute;
    left: 10px;
    color: var(--gray);
}
.form-table .tbl-type-field{
    width: 150px;
}
.form-table .tbl-price-field{
    width: 150px;
}
.form-table .tbl-form-name-field{
    width: 200px;
}
.form-table .form-input-with-close .table-input {
    width: 93%;
}
.form-table .action-btns {
    font-size: 20px;
    cursor: pointer;
    transition: .2s;
}
.form-table .action-btns:hover {
    color: var(--primary);
}
.section-table{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: self-start;
}
.add-new-row {
    font-size: 22px;
    background: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 60px;
    color: var(--link-color-hover);
}
.add-new-row:hover{
    color: var(--dark-blue-hover);
}
.section-table form {
    width: 100%;
}
.form-table.total-calculation-table {
    width: 400px;
    margin-left: 20px;
}
.form-table.final-calculation-table {
    width: 60rem;
    margin-left: 20px;
}
table.form-table.total-calculation-table .tbl-long-field .submit-button {
    width: 100%;
}
table.simple-table tr td {
    padding: 0;
    font-size: 14px;
}
table.simple-table tr td:first-child {
    padding: 5px 70px 5px 0px;
}
.back-page-container {
    padding: 15px 0;
    border-bottom: 1px solid #9CA3AF;
}
.back-page-container a {
    color: #6E7583;
    font-size: 14px;
    display: flex;
    width: 190px;
    align-items: center;
    justify-content: space-between;
}
.back-page-container svg {
    font-size: 22px;
    color: #000000;
    transition: .2s;
}
.back-page-container a:hover,
.back-page-container a:hover svg{
    color: var(--dark-blue-hover);
}
.form-table .action-btns {
    font-size: 20px;
    cursor: pointer;
    transition: .2s;
    color: #9CA3AF;
    padding: 6px 5px;
    line-height: 0;
}
.form-table .tbl-buttons-field {
    display: flex;
    width: 80px;
    align-items: center;
    justify-content: space-between;
}
.action-btns.icon-eye {
    font-size: 22px;
}
.search-estimates-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0 20px 0;
}
.search-estimates-wrapper .search-input-field {
    border: 1px solid #D1D5DB;
    border-radius: 5px;
    padding: 16px 20px;
    outline: 0;
    font-size: 14px;
    width: 100%;
}
.search-est-items {
    width: 100%;
}
.search-est-items.sei-with-icons {
    position: relative;
}
.search-est-items.sei-with-icons span {
    position: absolute;
    color: #9CA3AF;
    font-size: 20px;
    right: 14px;
    top: 16px;
}
.full-table{
    width: 100% !important;
}
.date-fields {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    border-radius: 5px;
    border: 1px solid var(--light-gray);
}
.date-fields .date-input {
    border: 0;
    outline: 0;
    font-size: 14px;
    padding: 10px;
    display: block;
    width: 100%;
    background: transparent;
}
.date-fields span{
    color: #9CA3AF;
}
.form-table .tbl-qty-field {
    width: 100px;
    text-align: center;
}

/* Form Controls */
.form-control {
    border: 1px solid #D1D5DB;
    border-radius: 5px;
    font-size: 14px;
    padding: 14px;
    outline: 0;
}
.form-grouped {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.form-group-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}
.row-container .col-6 {
    width: 49%;
}
.form-group-wrapper .row-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.form-grouped label {
    color: #374151;
    font-weight: 600;
    font-size: 14px;
    line-height: 2;
}
.form-grouped label span {
    color: #9CA3AF;
    font-weight: 400;
    font-size: 12px;
    line-height: 2;
}
.form-grouped label a {
    color: var(--primary);
    font-weight: 400;
    font-size: 12px;
}
.form-grouped.from-group-checkbox {
    flex-direction: row;
}
.col-12 {
    width: 100%;
}
.form-grouped.from-group-checkbox input.table-input{
    margin-right: 14px;
}
.form-input-with-desc p {
    color: #ff6800;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
}
.form-err-msg {
    display: block;
    font-size: 12px;
    color: #f00;
    margin-top: 3px;
}
.table-input.promo-code-field {
    width: 200px;
}
.expires-notice {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-align: left;
    padding: 10px 0px;
    color: #9CA3AF;
    margin-bottom: 10px;
}
.expires-notice span{
    color: var(--primary) !important;
    display: block;
    padding: 0 5px !important;
}
/* Mobile Menu  */
button.backmenu-icon {
    display: none;
}
button.backmenu-icon span.sr-only {
   display: none;
}
button.backmenu-icon .w-6 {
    width: 35px !important;
}
button.backmenu-icon {
    background: #fff;
    border: none;
    position: absolute;
    top: 20px;
    z-index: 10000;
    right: -75px;
    line-height: 0;
    padding: 3px;
    border-radius: 5px;
}
button.backmenu-icon:hover {
    opacity: 0.75 ;
} 
label.field-label {
    display: none;
}
label.field-label {
    padding-bottom: 5px;
}
/* Sidebar  */
li.comp-logo-sidebar.mobile {
    display: none;
}
/* Thank You Screen */
.ty-icon img {
    width: 400px;
}
.ty-icon img {
    width: 250px;
}
.ty-msg h1 {
    color: var(--heading-color);
}
.ty-msg h4 {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7em;
}
.message-form {
    display: flex;
    width: 100%;
    box-shadow: 0px 5px 10px #00000014;
    padding: 50px 40px;
    justify-content: center;
    background: #fbfcfd;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    margin: 40px 0 !important;
    height: 100vh;
    margin-top: 100px !important;
}
.ty-msg .submit-button {
    margin: 0 auto;
    margin-top: 20px;
    width: 300px;
}
section.main-form-section.thank-you .form-section-wrapper {
    margin: 0 20px 0 0;
    padding: 0;
    width: 100%;
}
.ty-msg h1.page-title {
    font-size: 30px;
    margin: 20px 0;
}
section.main-form-section.thank-you {
    max-width: 1440px;
    margin: 0 auto;
}
.calendar-wrapper iframe {
    border: none;
    background: #fff;
    overflow: hidden;
}
.ty-msg {
    padding: 0 20px;
}
/* Video Content Style */
.calendar-content {
    position: relative;
    padding-bottom: 62.5%;
    height: 100vh;
    width: 60%;
}
.calendar-content iframe{
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
    border: 0;
}
.message-form-left {
    width: 40%;
}

/* DATATABLES */
.datatable-wrapper {
    position: relative;
}
.datatable-wrapper .form-table tr td {
    color: #374151;
    padding: 14px 20px;
}
.datatable-wrapper .dataTables_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.datatable-wrapper .form-table{
    width: 100% !important;
    margin: 20px 0;
    margin-top: 5rem;
}
.datatable-wrapper .dataTables_length select,
.datatable-wrapper .dataTables_filter input[type="search"] {
    color: #374151;
    border: 1px solid #D1D5DB;
    border-radius: 5px;
    padding: 10px 15px;
    outline: 0;
}
.datatable-wrapper .dataTables_filter input[type="search"]{
    margin-left: 5px;
}
.dataTables_paginate .paginate_button {
    color: #ffffff;
    background: #0b0243;
    font-size: 12px;
    padding: 8px 13px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 1px;
    border: 1px solid #0b0243;
    transition: .2s;
}
.dataTables_paginate .paginate_button:hover:not(.current):not(.disabled){
    color: #0b0243;
    background: #ffffff;
    border: 1px solid #0b0243;
}
.dataTables_paginate .paginate_button.disabled {
    background: #ffffff;
    color: #d5d5d5;
    border: 1px solid #d5d5d5;
    cursor: no-drop;
}
.form-table thead tr th.sorting {
    cursor: pointer;
}
.table-filter-options {
    display: flex;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}
.table-filter-options .filter-group select, .table-filter-options .filter-group input {
    color: #374151;
    border: 1px solid #D1D5DB;
    border-radius: 5px;
    padding: 10px 15px;
    outline: 0;
    width: 100%;
    font-size: 14px;
}
.table-filter-options .filter-group {
    width: 44%;
}
.table-filter-options .filter-group-btn {
    width: 10%;
}
.filter-button {
    border: 1px solid #0b0243;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    color: #ffffff;
    background: #0b0243;
}
.filter-button:hover {
    border: 1px solid #0b0243;
    color: #0b0243;
    background: #fff;
}
/* Dropdowns */
.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 auto;
}
.dropbtn {
    background-color: #0b0243;
    color: white;
    padding: 10px 15px;
    font-size: 12px;
    border: none;
    width: 100%;
    border-radius: 5px;
    transition: .2s;
}
.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    box-shadow: 0px 5px 10px #00000014;
    transition: .2s;
}
.dropdown-content a {
    color: #0b0243;
    padding: 7px 10px;
    text-decoration: none;
    display: block;
    background: #fff;
    transition: .2s;
    font-size: 12px;
}
.dropdown-content a:hover {
    background: #eee;
}
.dropdown:hover .dropdown-content {
    display: block;
    transition: .2s;
}
span.tbl-status {
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
    background: #0b0243;
    width: 95px;
    color: #fff !important;
    text-align: center;
    padding: 5px !important;
    font-weight: 600;
}
a.paginate_button.current {
    border: 1px solid #0b0243;
    background: #fff;
    color: #0b0243;
}
span.status-Pending {
    background: #ff6c00;
}
span.status-Canceled {
    background: #f52323;
}
span.status-Completed {
    background: #15b734;
}
/* Dashboard */
.card-box {
    padding: 20px;
    border: 1px solid rgba(54, 64, 74, 0.05);
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    margin-bottom: 20px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-box.x3-box{
    width: 33%;
}
.card-box p.text-muted {
    font-size: 16px;
    margin-top: 10px;
}
.widget-bg-color-icon .bg-icon {
    height: 80px;
    width: 80px;
    text-align: center;
    border-radius: 50%;
    -moz-border-radius: 50%;
    background-clip: padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}
.widget-bg-color-icon .bg-icon-info {
    background-color: rgba(52, 211, 235, 0.2);
    border: 1px solid #34d3eb;
    color: #34d3eb;
}
.widget-bg-color-icon .bg-icon-danger {
    background-color: rgba(251, 109, 157, 0.2);
    border: 1px solid #fb6d9d;
    color: #fb6d9d;
}
.widget-bg-color-icon .bg-icon-purple {
    background-color: rgba(114, 102, 186, 0.2);
    border: 1px solid #7266ba;
    color: #7266ba;
}
.widget-bg-color-icon .bg-icon-success {
    background-color: rgba(129, 200, 104, 0.2);
    border: 1px solid #81c868;
    color: #81c868;
}
.text-right {
    text-align: right;
}
.widget-bg-color-icon .bg-icon i {
    font-size: 32px;
    line-height: 80px;
}
.text-dark {
    color: #0B0243 !important;
}
.text-muted {
    color: #98a6ad;
}
.card-box b.counter {
    font-size: 24px;
}
.widget-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dashboard-header-section .page-title-sm {
    font-size: 18px;
    margin-bottom: 5px;
    font-family: inherit;
    color: #2c2c2c;
    font-weight: 400;
}
.promocodes-widget {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #00000014;
    width: 25%;
    padding: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-left: 20px;
}
.promocodes-widget .promo-cicon {
    height: 80px;
    width: 80px;
    text-align: center;
    border-radius: 50%;
    -moz-border-radius: 50%;
    background-clip: padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    background-color: #ff851b38;
    border: 1px solid #ff851b;
    color: #ff851b;
}
.promocodes-widget .promocodes-widget p {
    font-size: 16px;
    margin: 20px 10px 10px 10px;
    color: #98a6ad;
}
.promocodes-widget h4 {
    color: #0b0243;
    font-size: 40px;
    font-family: inherit;
    margin: 0;
    line-height: 1;
}
.promocodes-widget p {
    color: #797979;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
}

/* Custom Modal */
.custom-modal {
    /* display: none; */
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}
.custom-modal-container {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    border-radius: 10px;
    overflow: hidden;
}
.custom-modal-inner {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.custom-modal-inner h2 {
    width: 100%;
    text-align: left;
    font-size: 26px;
    margin-bottom: 20px;
}
.custom-modal-inner p {
    font-size: 16px;
    margin: 10px 0;
    text-align: left;
}
.custom-modal-inner ul {
    margin-left: inherit;
    font-size: 16px;
    text-align: left;
    background: #c7d3e5;
    color: #003690;
    padding: 20px;
    border-radius: 10px;
}
.help-img {
    display: flex;
    padding: 40px;
}
.help-img img {
    width: 270px;
}
.custom-modal-inner .instructions {
    background: #f9f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    text-align: left;
}
.help-button {
    position: fixed;
    z-index: 1;
    bottom: 20px;
    right: 20px;
}
.help-button .float-help-btn {
    background: #0b0243;
    color: #fff;
    border-radius: 20px;
    padding: 12px 15px;
    font-size: 14px;
    text-transform: uppercase;
    transition: .2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eee;
}
.help-button .float-help-btn svg path {
    stroke: #fff;
}
.help-button .float-help-btn svg {
    margin-right: 5px;
    font-size: 20px;
}
.help-button .float-help-btn:hover {
    background: var(--dark-blue-hover);
}
.custom-modal-inner .instructions .help-img {
    padding: 0;
    margin-bottom: 15px;
}
/* Add Animation */
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}
@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

/* Nestable */
.section-table.nestable-lists {
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #00000014;
    border-radius: 5px;
    overflow: hidden;
}
.section-table.nestable-lists .nestable{
    width: 100%;
    padding: 20px;
}
.section-table.nestable-lists h5 {
    width: 100%;
    background: #0b0243;
    color: #fff;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    padding: 15px;
}
ol.nestable-list.nestable-group .nestable-item {
    border-radius: 3px;
    background: #fff;
    margin-bottom: 10px;
    overflow: hidden;
    border: 1px solid #eee;
    cursor: grab;
}
ol.nestable-list.nestable-group .nestable-item:hover {
    background: #f9f9f9;
}
ol.nestable-list.nestable-group .nestable-item:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.nestable-item-name span.drag-icon {
    font-size: 25px;
    line-height: 0;
    background: #eee;
    color: #9e9a9a;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nestable-item-name span.drag-text {
    font-size: 14px;
    margin-left: 5px;
    padding: 5px 10px;
    width: 100%;
}
.nestable-item-name {
    display: flex;
    align-items: center;
}